window.Popper = require('popper.js');

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

window.$ = window.jQuery = require('jquery');

// If you wish to use all of bootstrap JS library (https://getbootstrap.com)
// import 'bootstrap';
//
// Otherwise include only what you need:
//
import 'bootstrap/js/dist/alert.js'
import 'bootstrap/js/dist/button.js'
// import 'bootstrap/js/dist/carousel.js'
// import 'bootstrap/js/dist/collapse.js'
// import 'bootstrap/js/dist/dropdown.js'
import 'bootstrap/js/dist/modal.js'
// import 'bootstrap/js/dist/popover.js'
// import 'bootstrap/js/dist/scrollspy.js'
import 'bootstrap/js/dist/tab.js'
// import 'bootstrap/js/dist/tooltip.js'
// import 'bootstrap/js/dist/utils.js'